<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">Products</router-link>
                </p>
                </v-col>
            </v-row>
        <v-row justify="center" class="py-5 mt-2" v-if="product">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ product.name }}</h1>
            <p class="text-caption text-center">
                <span v-if="productTypeText[product.type]">{{ productTypeText[product.type] }}</span>
                <span v-if="!productTypeText[product.type]">{{ product.type }}</span>
            </p>
            </v-col>
        </v-row>

        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">How to delete this product</h1>
                <p class="mt-8">You can completely delete a product if it doesn't appear in any orders.</p>
                <p>If you only have test orders for this product, delete the test account first and then try to delete the product.</p>
                <p>If the product appears in any orders, you can mark the product as deleted which means it won't be visible or available for purchase by anyone, won't appear in administrator searches (unless you specifically ask to look for deleted products), and won't appear anywhere except in order histories.</p>
                <p>Delete button is coming soon!</p>
                <template v-if="isPermitServiceAdmin">
                <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                <p class="mb-0 pb-0">
                    <v-btn color="red" class="white--text" @click="deleteProduct">Delete Product</v-btn>
                </p>
                </template>
            </v-col>
        </v-row>

<!--
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-overline mb-0">Product type</p>
                <p>
                    <span>{{ currentProduct.type }}</span>
                </p>
                <p class="text-overline mb-0">Product name</p>
                <p>
                    <span>{{ displayName }}</span>
                </p>
                <p class="text-overline mb-0">Product image</p>
                <p>
                    <Avatar :attr="currentProduct" :size="36"/>
                </p>

                <template v-if="currentProduct.type === ACCOUNT_TYPE_FREE">
                <p class="text-overline mb-0">Related products <span>({{ combinedProductList.length - 1 }})</span></p>
                <v-list class="ma-0 pa-0" v-if="combinedProductList.length > 1">
                    <template v-for="(product, idx) in combinedProductList">
                    <v-list-item @click="selectProduct(product.id)" v-bind:key="product.id" class="ma-0 py-1 px-4" v-show="product.id !== currentProduct.id">
                        <v-list-item-avatar tile style="margin-right: 8px;">
                            <Avatar :attr="product" :size="32"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ product.name }}</v-list-item-title>
                            <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ product.type }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-spacer></v-spacer>
                    </v-list-item>
                    <v-divider v-bind:key="idx"></v-divider>
                    </template>
                </v-list>
                </template>

                <template v-if="currentProduct.type === ACCOUNT_TYPE_ENTERPRISE">
                <p class="text-overline mb-0">Authentication realms <span>({{ realmList.length }})</span></p>
                <v-list class="ma-0 pa-0" v-if="realmList.length > 0">
                    <template v-for="(realm, idx) in realmList">
                    <v-list-item v-bind:key="realm.id" class="ma-0 py-1 px-4">
                        <v-img contain :src="getRealmIcon(realm.icon)" v-if="realm.icon" max-width="34" max-height="34" class="mr-5"/>
                        <v-list-item-content class="text-overline" style="font-size: 14px!important">
                            {{ realm.label }}
                        </v-list-item-content>
                        <v-spacer></v-spacer>
                    </v-list-item>
                    <v-divider v-bind:key="idx"></v-divider>
                    </template>
                </v-list>
                </template>
            </v-col>
        </v-row> -->
        <!-- <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p>
                    <v-btn class="red--text" @click="dialogDeleteProduct = true" outlined :disabled="isDeleteProductDisabled">
                        Delete product
                    </v-btn>
                </p>
                <p v-if="currentProduct.type === ACCOUNT_TYPE_FREE && combinedProductList.length > 1" class="red--text">
                    The related products must be deleted, or you must unlink yourself from them, before the this product can be deleted.
                </p>
                <p v-if="currentProduct.type === ACCOUNT_TYPE_ENTERPRISE && realmList.length > 0" class="red--text">
                    The authentication realms must be deleted before the product can be deleted.
                </p>
                <v-dialog v-model="dialogDeleteProduct" max-width="600">
                    <v-card elevation="4" class="pa-5" style="border: solid 1px red;">
                        <v-card-text class="text-h6 pa-0 pb-3 red--text">Delete product</v-card-text>
                        <v-row justify="center" class="pt-3">
                            <v-col cols="12">
                                <p class="font-weight-light text-body-1">This action is not reversible.</p>
                                <p class="text-body-1 font-weight-light pb-2 red--text">All product data will be permanently deleted.</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="red white--text" @click="deleteProduct" :disabled="isDeleteProductDisabled">
                                Delete
                            </v-btn>
                            <v-btn text class="grey--text" @click="dialogDeleteProduct = false">Cancel</v-btn>
                        </v-row>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row> -->
        </v-col>
    </v-row>
</template>

<style lang="css" scoped>
/* p.pointer {
     cursor: pointer;
}
span.pointer {
     cursor: pointer;
} */
@media print {
  /* add margin around the main content to separate it from the page header and footer */
  @page {
    margin-top: 2cm;
    margin-bottom: 2cm;
  }
  /* removes padding corresponding to navbar and footer when printing document, adds one line of padding to top of document to separate page title from page content */
  .print {
    padding: 0 !important;
  }
  /* hides elements that should not appear in print */
  .no-print {
      display: none !important;
  }

}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import RequireLogin from '@/components/RequireLogin.vue';
// import Snackbar from '@/components/Snackbar.vue';
// import Avatar from '@/components/Avatar.vue';
// import TextLink from '@/components/TextLink.vue';
import { compact } from '@/sdk/input';
// import { ACCOUNT_TYPE_ENTERPRISE, ACCOUNT_TYPE_PERSONAL, ACCOUNT_TYPE_FREE } from '@/constants';

export default {
    components: {
        // RequireLogin,
        // Snackbar,
        // Avatar,
        // TextLink,
    },
    data: () => ({
        product: null,
        error: false,
        // TODO: this is repeated in ViewProduct and DialogCreateProduct, need to consolidate into a library
        productTypeText: {
            test: 'Test Product',
            physical: 'Physical Product',
            digital: 'Digital Product', // download, access pass
            appt: 'Appointment',
            service: 'Service', // can be done with or without an appointment, in person, by mail, or online
            member: 'Membership', // any membership service, for physical or digital access
            saas: 'Software as a Service', //  special combination of membership, digital access, and ongoing services; could be flat rate or metered
        },
        dialogDeleteProduct: false,
        // TODO delete these old variables
        snackbar: {},
        snackbarProductDeleted: false,
        snackbarProductDeleteFailed: false,
        realmImage: [],
        realmMap: {},
    }),

    /*
    props: ['isAuthenticated', 'isReady', 'isGravatarEnabled'],
*/
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            productMap: (state) => state.productMap,
            currentProductId: (state) => state.productId,
        }),
        ...mapGetters({
            currentProduct: 'product', // currently selected product object
            combinedProductList: 'combinedProductList',
        }),
        // TODO: this computed method appears in many places already, it should be moved to vuex store and obtained via mapGetters, OR it should be made into a component like <PermitServiceAdmin>...</PermitServiceAdmin> that will use this computed method and make the conetnt visible or not visible ,  like RequireLogin or what we do in App.vue , instead of repeating the same logic everywhere;  it's just for UI,  server will enforce the role for security, so all functions can still be available in the parent component
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        compactProductNames() {
            return this.combinedProductList.map((item) => compact(item.name));
        },
        currentProductImageWithGravatarDisabled() {
            return { icon: this.currentProduct.icon, isGravatarEnabled: false };
        },
        isDeleteProductDisabled() {
            // return (this.currentProduct.type === ACCOUNT_TYPE_FREE && this.combinedProductList.length > 1) || (this.currentProduct.type === ACCOUNT_TYPE_ENTERPRISE && this.realmList.length > 0);
            return this.combinedProductList.length > 1;
        },
        compactRealmLabels() {
            return this.realmList.map((item) => compact(item.label || ''));
        },
        compactRealmDomains() {
            return this.realmList.map((item) => compact(item.domain || ''));
        },
        realmList() {
            const list = Object.values(this.realmMap);
            list.sort((a, b) => (`${a.label}`).localeCompare(b.label));
            console.log('list', list);
            return list;
        },
        // ACCOUNT_TYPE_ENTERPRISE() {
        //     return ACCOUNT_TYPE_ENTERPRISE;
        // },
        // ACCOUNT_TYPE_PERSONAL() {
        //     return ACCOUNT_TYPE_PERSONAL;
        // },
        // ACCOUNT_TYPE_FREE() {
        //     return ACCOUNT_TYPE_FREE;
        // },
    },

    watch: {
        // isGravatarEnabled(val, oldval) {
        //     console.log('isGravatarEnabled: %o (was: %o)', val, oldval);
        //     this.isGravatarEnabled = val;
        // },
        // Close when clicking outside of dialog
        isAuthenticatedReady(value, oldValue) {
            console.log('product.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogDeleteProduct(val) {
            if (!val) {
                this.closeDialogDeleteProduct();
            }
        },
    },

    created() {
        console.log('created'); // : isAuthenticated: %o', this.isAuthenticated);
        if (this.$route.query.acct) {
            const queryProductId = this.$route.query.acct;
            if (queryProductId !== this.currentProductId) {
                console.log(`product.vue: setting current product to ${queryProductId}`);
                this.$store.commit('setProductId', queryProductId);
            }
        }
        // if (!this.currentProduct.domain) {
        //     this.isDeveloperExpansionPanelOpen = [0];
        // }
    },

    methods: {
        async loadProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProduct: true });
                const response = await this.$client.account(this.$route.params.accountId).product.get({ id: this.$route.params.productId });
                console.log(`product/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.product = response;
                } else {
                    // TODO: redirect back to product list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load product', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProduct: false });
            }
        },
        async init() {
            // load concurrently:
            // if (this.currentProduct.type === ACCOUNT_TYPE_ENTERPRISE) {
            //     await Promise.all([
            //         this.fetchRealmList(),
            //     ]);
            // }
            this.displayName = this.currentProduct.name;
        },
        async fetchRealmList() {
            try {
                // const realmListResponse = await loginshield.product.getRealmList({ productId: this.currentProduct.id });
                const realmListResponse = {};
                if (realmListResponse && realmListResponse.data) {
                    const map = {};
                    realmListResponse.data.forEach((item) => {
                        const itemCopy = { ...item };
                        if (itemCopy.android && typeof itemCopy.android === 'object' && typeof itemCopy.android.allowLoginFrom === 'object' && itemCopy.android.allowLoginFrom.length > 0) {
                            itemCopy.androidApplicationId = itemCopy.android.allowLoginFrom[0].applicationId;
                        } else {
                            itemCopy.androidApplicationId = null;
                        }
                        map[item.id] = itemCopy;
                    });
                    this.realmMap = map;
                    console.log('init realmMap: ', this.realmMap);
                }
            } catch (error) {
                console.log('fetchRealmList failed', error);
            }
        },
        async deleteProduct() {
            try {
                this.$store.commit('loading', { deleteProduct: true });
                const response = await this.$client.account(this.$route.params.accountId).product.delete({ id: this.$route.params.productId });
                if (response.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted the product' });
                    this.$router.push({ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } });
                } else {
                    console.log('deleteproduct.vue: deleteProduct failed: %o', response);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete product' });
                }
            } catch (err) {
                console.log('deleteproduct.vue: deleteProduct failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete product' });
            } finally {
                this.closeDialogDeleteProduct();
                this.$store.commit('loading', { deleteProduct: false });
            }
        },
        closeDialogDeleteProduct() {
            this.dialogDeleteProduct = false;
        },
        getRealmIcon(imageBase64) {
            console.log('getRealmIcon');
            if (imageBase64) {
                return `data:image/png;base64,${imageBase64}`;
            }
            return '';
        },
        async selectProduct(id) {
            await this.$store.dispatch('switchProduct', id);
            this.$router.push({ path: '/dashboard', query: { acct: id } });
        },
    },
    mounted() {
        console.log('mounted');
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        this.loadProduct();
    },
};
</script>
